import L from 'leaflet';

const boeiIcon = L.icon({
    iconUrl: '/icons/buoy.png',
    iconSize: [20, 20],
    iconAnchor: [16, 16],
    popupAnchor: [0, -16]
  });

const addMarksAndDrawRoute = (map, courseMarks, startLineCoordinates, finishLineCoordinates) => {
  // Voeg merktekens toe
  courseMarks.forEach((mark, index) => {
    L.marker([mark.lat, mark.lon], { icon: boeiIcon }).addTo(map)
      .bindPopup(`Mark ${index + 1}`);
  });

  // Dynamisch samenstellen van de route
  const routeCoordinates = [
    [(startLineCoordinates[0].lat + startLineCoordinates[1].lat) / 2, (startLineCoordinates[0].lon + startLineCoordinates[1].lon) / 2]
  ];

  // Voeg de coördinaten van de merktekens toe
  courseMarks.forEach(mark => {
    routeCoordinates.push([mark.lat, mark.lon]);
  });

  // Voeg het midden van de finishlijn toe
  routeCoordinates.push([(finishLineCoordinates[0].lat + finishLineCoordinates[1].lat) / 2, (finishLineCoordinates[0].lon + finishLineCoordinates[1].lon) / 2]);

  // Teken het pad met de route
  L.polyline(routeCoordinates, { color: 'black' }).addTo(map);
};

export default addMarksAndDrawRoute;
