// api.js
const backendUrl = 'https://rotc.knoxfm.nl';
const backendPort = process.env.REACT_APP_BACKEND_PORT
//console.log(backendUrl)
export async function fetchLatestAISData() {
  try {
    const response = await fetch(`${backendUrl}/api/latest_ais_data`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching latest AIS data:',backendUrl, error);
    throw error;
  }
}

