import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { fetchLatestAISData } from './api'; // Voeg de API-aanroep toe
// Importeer raceCourse uit raceCourse.json
import raceCourse from './data/raceCourse.json';
// Importeer exclusion zones
import exclusionZonesData from './data/exclusionZones.json';
import drawLines from './drawStartFinishLine'; // Nieuwe import
import addMarksAndDrawRoute from './drawCourse'; // Nieuwe import
import parseUTCStringToDate from './dateUtils'; // Import the utility function
import drawExclusionZones from './drawExclusionZones'; 

let shipMarkers = [];

const WorldMap = () => {
  const [shipPositions, setShipPositions] = useState([]);

  useEffect(() => {
    const map = L.map('map-container').setView([52.0, 4.5], 8.5);

    // Base layer: OpenStreetMap
    const openStreetMapLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors'
    });
    // Overlay layer: OpenSeaMap seamarks
    const openSeaMapLayer = L.tileLayer('https://t1.openseamap.org/seamark/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenSeaMap contributors'
    });

    // Voeg beide lagen toe aan de kaart
    openStreetMapLayer.addTo(map);
    openSeaMapLayer.addTo(map);

    const { startLineCoordinates, finishLineCoordinates, courseMarks } = raceCourse;
    // Aanroepen van het tekenen op de kaart
    drawLines(map, startLineCoordinates, finishLineCoordinates); // Start en Finishlijn
    addMarksAndDrawRoute(map, courseMarks, startLineCoordinates, finishLineCoordinates); //Course route
    drawExclusionZones(map, exclusionZonesData); // exclusionzones


    // Voer de API-aanroep uit bij het laden en daarna elke 5 minuten
    const fetchAISData = async () => {
      try {
        const data = await fetchLatestAISData();
        console.log('Received latest AIS data:', data);
        setShipPositions(data);

        // Verwijder bestaande scheepsiconen van de kaart
        shipMarkers.forEach(marker => marker.remove());
        shipMarkers = [];
        // Haal iconen op voor elk bedrijf
        const createShipIcon = (company) => {
          const iconFilename = company.replace(/\s+/g, '-').toLowerCase();
          const iconUrl = `/iconscompanies/${iconFilename}-icon.png`;
          const finalIconUrl = doesFileExist(iconUrl) ? iconUrl : '/iconscompanies/ship-icon.png';       
          const desiredHeight = 50;

          return L.icon({
            iconUrl: finalIconUrl,
            iconSize: [null, desiredHeight],  // Breedte wordt automatisch ingesteld op basis van de aspect ratio
            iconAnchor: [desiredHeight / 2, desiredHeight / 2],  // Plaats het anker in het midden van het pictogram
            popupAnchor: [0, -desiredHeight / 2]  // Zorg dat de popup goed wordt gepositioneerd
          });
        };

        // Functie om te controleren of een bestand bestaat
        const doesFileExist = (url) => {
          const http = new XMLHttpRequest();
          http.open('HEAD', url, false);
          http.send();
          return http.status !== 404;
        };

        // Voeg nieuwe scheepsiconen toe aan de kaart
        data.forEach(ship => {
          try {
            const shipTimeUTCDate = parseUTCStringToDate(ship.TimeUTC); // Use the utility function to parse the UTC timestamp
            // Get the current UTC time
            const currentTimeUTC = new Date();
            // Calculate the difference in minutes
            const timeDifferenceInMinutes = Math.ceil((currentTimeUTC - shipTimeUTCDate) / (1000 * 60));
            const legDistance = parseFloat(ship.LegDistanceTraveled.toFixed(2));
            const totalDistance = parseFloat(ship.TotalDistanceTraveled.toFixed(2));            
            const popupContent = `
            <div>
              <strong>Team:</strong> ${ship.Company}<br />
              <strong>Schip:</strong> ${ship.ShipName}<br />
              <strong>Snelheid:</strong> ${ship.Sog} knopen<br />
              <strong>Koers:</strong> ${ship.Cog} graden<br />
              <strong>Tijd (UTC):</strong> ${ship.TimeUTC}<br />
              <strong>Update:</strong> ${timeDifferenceInMinutes} minutes ago<br />
              <strong>Leg Distance:</strong> ${legDistance} NM<br />  
              <strong>Total Distance:</strong> ${totalDistance} NM<br /> 
            </div>
          `;

            // Creëer het juiste pictogram op basis van het bedrijf
            const shipIcon = createShipIcon(ship.Company);

            const shipMarker = L.marker([ship.Latitude, ship.Longitude], { icon: shipIcon })
              .bindPopup(popupContent)
              .addTo(map);
            shipMarkers.push(shipMarker);
          } catch (error) {
            console.error('Fout bij het aanmaken van schip:', error);
          }
        });
      } catch (error) {
        console.error('Error fetching latest AIS data:', error);
      }
    };

    fetchAISData(); // Voer het meteen uit bij laden

    const interval = setInterval(fetchAISData, 5 * 60 * 1000); // Voer elke 5 minuten uit

    return () => {
      clearInterval(interval); // Stop het interval bij het unmounten van de component
      map.remove();
    };
  }, []); // Geen dependencies, zodat dit effect alleen bij het laden wordt uitgevoerd

  return <div id="map-container" style={{ width: '100%', height: '100vh' }} />;
};

export default WorldMap;
