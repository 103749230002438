import L from 'leaflet';

const drawLines = (map, startLineCoordinates, finishLineCoordinates) => {
  // Voeg startlijn toe
  L.polyline(startLineCoordinates, { color: 'green' }).addTo(map);

  // Voeg finishlijn toe
  L.polyline(finishLineCoordinates, { color: 'red' }).addTo(map);
};

export default drawLines;
