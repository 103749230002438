import L from 'leaflet';

const drawExclusionZones = (map, exclusionZonesData) => {
    if (exclusionZonesData && exclusionZonesData.length > 0) {
      exclusionZonesData.forEach((zone, index) => {
        const polygon = L.polygon(zone.coordinates, {
          color: 'red',
          fillOpacity: 0.3
        }).addTo(map);
  
        polygon.bindPopup(`Exclusion Zone: ${zone.name}`);
      });
    }
  };
  
  export default drawExclusionZones;
  